import { inject }           from 'aurelia-framework';
import { BaseRepository }   from 'modules/core/services/base-repository';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class CompositionDataRepository extends BaseRepository {
    baseUrl = 'management/bituminous-mixtures/composition-data';

    /**
     * Fetches all fields from a study type
     *
     * @param compositionDataId
     * @return {*}
     */
    fields(compositionDataId) {
        return this.httpClient.get(`${this.baseUrl}/${compositionDataId}/fields`);
    }
}
