import { bindable, inject }                     from 'aurelia-framework';
import { I18N }                                 from 'aurelia-i18n';
import { BaseListViewModel }                    from 'base-list-view-model';
import { FilterFormSchema }                     from 'modules/management/bituminous-mixtures/association-specifications/filter-form-schema';
import { AppContainer }                         from 'resources/services/app-container';
import { Downloader }                           from 'resources/services/downloader';
import { AssociationSspecificationsRepository } from './services/repository';

@inject(AppContainer, AssociationSspecificationsRepository, I18N, Downloader, FilterFormSchema)
export class ListAssociationSspecifications extends BaseListViewModel {

    listingId = 'management-association-specifications-listing';

    @bindable headerTitle    = 'listing.management.bituminous-mixtures.association-specifications';
    @bindable newRecordRoute = 'management.bituminous-mixtures.association-specifications.create';
    @bindable repository;
    @bindable datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param i18N
     * @param downloader
     * @param filterFormSchema
     */
    constructor(appContainer, repository, i18N, downloader, filterFormSchema) {
        super(appContainer);

        this.repository       = repository;
        this.i18N             = i18N;
        this.downloader       = downloader;
        this.filterFormSchema = filterFormSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'management.bituminous-mixtures.association-specifications.manage',
            'management.bituminous-mixtures.association-specifications.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate() {
        super.activate();

        this.defineDatatable();

        this.filterSchema = this.filterFormSchema.schema(this);
        this.filterModel  = this.filterFormSchema.model(this);
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:      this.repository,
            show:            {
                action:  (row) => this.appContainer.router.navigateToRoute('management.bituminous-mixtures.association-specifications.view', { id: row.id }),
                visible: () => this.appContainer.authenticatedUser.can(['management.bituminous-mixtures.association-specifications.manage', 'management.bituminous-mixtures.association-specifications.view']),
            },
            edit:            {
                action:  (row) => this.appContainer.router.navigateToRoute('management.bituminous-mixtures.association-specifications.edit', { id: row.id }),
                visible: () => this.appContainer.authenticatedUser.can(['management.bituminous-mixtures.association-specifications.manage', 'management.bituminous-mixtures.association-specifications.edit']),
            },
            destroy:         {
                action:  (row) => this.repository.destroy(row.id),
                visible: () => this.appContainer.authenticatedUser.can(['management.bituminous-mixtures.association-specifications.manage', 'management.bituminous-mixtures.association-specifications.delete']),
            },
            options:         [],
            selectable:      true,
            destroySelected: true,
            sorting:         {
                column:    0,
                direction: 'asc',
            },
            columns:         [
                {
                    data:  'specification',
                    name:  'specification_revisions.code',
                    title: 'form.field.specification-book',
                },
                {
                    data:       'composition_data',
                    name:       'composition_data',
                    title:      'form.field.fields',
                    type:       'processed',
                    processor:  (row) => `<ul>` + row.composition_data.map((name) => `<li>${name}</li>`).join('') + `</ul>`,
                    searchable: false,
                    orderable:  false,
                },
                {
                    data:    'created_by',
                    name:    'users.name',
                    title:   'form.field.created-by',
                    display: false,
                },
                {
                    data:            'created_at',
                    name:            'association_specifications.created_at',
                    title:           'form.field.created-at',
                    display:         false,
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
            ],
        };
    }

}
