import { bindable, inject }     from 'aurelia-framework';
import { I18N }                 from 'aurelia-i18n';
import { BaseOrderedListViewModel }    from 'base-ordered-list-view-model';
import { FilterFormSchema }     from 'modules/management/bituminous-mixtures/bituminous-applications/filter-form-schema';
import { AppContainer }         from 'resources/services/app-container';
import { Downloader }           from 'resources/services/downloader';
import { BituminousApplicationsRepository } from './services/repository';

@inject(AppContainer, BituminousApplicationsRepository, I18N, Downloader, FilterFormSchema)
export class ListBituminousApplications extends BaseOrderedListViewModel {

    listingId = 'management-bituminous-applications-listing';

    @bindable headerTitle    = 'listing.management.bituminous-mixtures.bituminous-applications';
    @bindable newRecordRoute = 'management.bituminous-mixtures.bituminous-applications.create';
    @bindable repository;
    @bindable datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param i18N
     * @param downloader
     * @param filterFormSchema
     */
    constructor(appContainer, repository, i18N, downloader, filterFormSchema) {
        super(appContainer);

        this.repository       = repository;
        this.i18N             = i18N;
        this.downloader       = downloader;
        this.filterFormSchema = filterFormSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'management.bituminous-mixtures.bituminous-applications.manage',
            'management.bituminous-mixtures.bituminous-applications.view',
        ]);
    }

    /**
     * Handles activate event
     */
    async activate() {
        super.activate();

        this.canEdit = this.appContainer.authenticatedUser.can(['management.bituminous-mixtures.bituminous-applications.manage', 'management.bituminous-mixtures.bituminous-applications.edit']);

        await this.loadFullData();
        this.defineDatatable();

        this.filterSchema = this.filterFormSchema.schema(this);
        this.filterModel  = this.filterFormSchema.model(this);
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:      this.repository,
            dblClick:        !this.canEdit,
            show:            {
                action:  (row) => this.appContainer.router.navigateToRoute('management.bituminous-mixtures.bituminous-applications.view', { id: row.id }),
                visible: (row) => this.appContainer.authenticatedUser.can(['management.bituminous-mixtures.bituminous-applications.manage', 'management.bituminous-mixtures.bituminous-applications.view']),
            },
            edit:            {
                action:  (row) => this.appContainer.router.navigateToRoute('management.bituminous-mixtures.bituminous-applications.edit', { id: row.id }),
                visible: (row) => this.appContainer.authenticatedUser.can(['management.bituminous-mixtures.bituminous-applications.manage', 'management.bituminous-mixtures.bituminous-applications.edit']),
            },
            destroy:         {
                action:  (row) => this.repository.destroy(row.id),
                visible: (row) => this.appContainer.authenticatedUser.can(['management.bituminous-mixtures.bituminous-applications.manage', 'management.bituminous-mixtures.bituminous-applications.delete']),
            },
            options:         [
                {
                    label:   'form.button.export-to-excel',
                    icon:    'icon-file-excel',
                    action:  () => this.exportListing(this.i18N.tr('form.field.bituminous-applications'), !this.datatable.instance.selectedRows.length ? null : this.datatable.instance.selectedRows),
                    visible: (row) => this.appContainer.authenticatedUser.can(['management.bituminous-mixtures.bituminous-applications.manage', 'management.bituminous-mixtures.bituminous-applications.view']),
                },
            ],
            buttons:         [
                {
                    label:            'form.button.save-order',
                    icon:             'icon-list-ordered',
                    visible:          this.canEdit,
                    action:           async () => await this.updateOrder(),
                    outside_dropdown: true,
                    color:            'success',
                },
            ],
            selectable:      true,
            destroySelected: true,
            destroyed:       async () => {
                await this.loadFullData();
                await this.updateOrder();
                this.datatable.instance.reload();
            },
            sorting:         {
                column:    0,
                direction: 'asc',
            },
            columns:         [
                {
                    data:  'order',
                    name:  'bituminous_applications.order',
                    title: 'form.field.order',
                    center: this.canEdit,
                    type:   this.canEdit ? 'input' : null,
                    number: true,
                },
                {
                    data:  'name',
                    name:  'bituminous_application_translations.name',
                    title: 'form.field.name',
                },
                {
                    data:  'created_by',
                    name:  'users.name',
                    title: 'form.field.created-by',
                },
                {
                    data:            'created_at',
                    name:            'bituminous_applications.created_at',
                    title:           'form.field.created-at',
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
            ],
        };
    }

}
