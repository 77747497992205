import { inject }                       from 'aurelia-framework';
import { BituminousMixturesRepository } from 'modules/management/bituminous-mixtures/services/repository';
import { CompositionData }              from 'modules/management/models/composition-data';

@inject(BituminousMixturesRepository)
export class FormSchema {

    /**
     * Model default values
     */
    modelDefaults = {
        fields: [],
    };

    /**
     * Constructor
     *
     * @param bituminousMixturesRepository
     */
    constructor(bituminousMixturesRepository) {
        this.bituminousMixturesRepository = bituminousMixturesRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {CompositionData}
     */
    model() {
        let model = new CompositionData();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @param readonly
     * @returns {*[]}
     */
    schema(viewModel, readonly = false) {
        this.name = {
            type:       'text',
            key:        'name',
            label:      'form.field.field-name',
            size:       12,
            attributes: {
                maxlength: 255,
                readonly:  readonly,
            },
        };

        this.fields = {
            type:         'duallistbox',
            key:          'fields',
            label:        'form.field.fields-to-associate',
            size:         12,
            remoteSource: () => this.bituminousMixturesRepository.compositionMixtureFields(),
            attributes:   {
                disabled: readonly,
            },
        };

        const schema = [
            [this.name],
            [this.fields],
        ];

        if (readonly) {
            this.created_by = {
                type:       'text',
                key:        'created_by',
                label:      'form.field.created-by',
                size:       6,
                required:   false,
                attributes: {
                    readonly: true,
                },
            };

            this.created_at = {
                type:       'text',
                key:        'created_at',
                label:      'form.field.created-at',
                size:       6,
                required:   false,
                attributes: {
                    readonly: true,
                },
            };

            return [...schema, [this.created_by, this.created_at]];
        }

        return schema;
    }

    /**
     * Returns buttons schema
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    globalSchema(viewModel, readonly = false) {
        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     () => viewModel.redirectToRoute('management.bituminous-mixtures.composition-data.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.clearButton = {
            type:       'button',
            label:      viewModel.create ? 'form.button.clear' : 'form.button.reverse-changes',
            action:     () => viewModel.resetForm(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
                this.clearButton,
                this.submitButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

}
